import logo from './ComingSoon.jpg';
import './App.css';
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { FiFacebook } from "react-icons/fi";
import { RiTiktokLine } from "react-icons/ri";

function App() {
  return (
    <div className="container">
      <header className="header">
        <img src={logo} className="responsive" alt="logo" />
      </header>
      <div className='socials'>
        <FaInstagram color='#000' size={50} onClick={()=> window.open('https://instagram.com/StekkiePlanten')} />
        <FaXTwitter color='#000' size={50} onClick={()=> window.open('https://x.com/StekkiePlanten')} />
        <RiTiktokLine color='#000' size={50}  onClick={()=> window.open('https://tiktok.com/@StekkiePlanten')} />
        <FiFacebook color='#000' size={45} />
        <TfiEmail color='#000' size={50} onClick={()=> window.location.assign('mailto:info@stekkieplanten.nl')} />
      </div>
    </div>
  );
}

export default App;
